import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-banner',
  templateUrl: './news-banner.component.html',
  styleUrls: ['./news-banner.component.scss'],
})
export class NewsBannerComponent implements OnInit {
  open = true;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  isDash(): boolean {
    return this.url.startsWith('/dash');
  }

  isLanding(): boolean {
    return this.url.startsWith('/landing');
  }

  isEmbed(): boolean {
    return this.url.startsWith('/embed');
  }

  isSurvey(): boolean {
    return this.url.startsWith('/survey');
  }

  get url(): string {
    return this.router.url;
  }
}
