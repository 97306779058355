import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface Alert {
  type: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  topNavNoBG: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showLoadingOverlay: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingOverlayMessage: BehaviorSubject<string> = new BehaviorSubject('');
  currentSection: string;
  alerts: Alert[] = [];
  viewProfile: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  // triggerAlert(message: string, type: string) {
  //   let al: Alert;

  //   al = {
  //     type: type,
  //     message: message,
  //   };

  //   this.alerts.push(al);
  // }

  // close(alert: Alert) {
  //   this.alerts.splice(this.alerts.indexOf(alert), 1);
  // }

  toggleOverlay(message?: string) {
    let m = message || '';

    if(this.showLoadingOverlay.value) {
      this.showLoadingOverlay.next(false);
    }
    else {
      this.showLoadingOverlay.next(true);
      this.loadingOverlayMessage.next(m);
    }
  }
}
