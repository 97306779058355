import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService implements OnDestroy {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  } 

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
} 