import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, from, Observable, Subject } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { Router } from '@angular/router';
import { FBUser } from '../shared/models/models';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import {
  Auth,
  authState,
  user,
  signOut,
  sendPasswordResetEmail,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AccountService implements OnDestroy {
  constructor(
    private router: Router,
    private toastService: ToastService,
    private auth: Auth,
    private firestore: Firestore,
    private fns: AngularFireFunctions,
  ) {
    // 1. subscrive to the user from the authsate
    authState(auth)
      .pipe(
        switchMap((user) => {
          // 2. get their user profile
          if (!user) {
            // if the user is null
            // console.log('From AccoutService Constructor: User is null - ', user);
            if (this.currentUser.value !== null) {
              console.log('User appears to have signed out.');
              this.currentUser.next(null);
              this.router.navigate(['/login']);
            }

            return EMPTY; // Return an empty observalbe because we don't really have aything...
          } else {
            return from(user.getIdToken(true)).pipe(
              switchMap((token) => {
                return this.getUserInfo(user.uid).pipe(
                  tap(console.log),
                  map((profile: any) => {
                    const userData = profile;
                    const retUser: FBUser = {
                      uid: user.uid,
                      token: token,
                      authToken: userData.authToken,
                      displayName: user.displayName,
                      firstName: userData.firstName,
                      lastName: userData.lastName,
                      email: userData.email,
                      roles: userData.roles,
                      workflowStage: userData.workflowStage,
                      couplet: userData.couplet,
                      prod: userData.prod ? true : false,
                      emailVerified: user.emailVerified,
                      teams: userData.teams ? true : false,
                      defaultPaymentMethod: userData.defaultPaymentMethod,
                      teamsCount: userData.teamsCount,
                      maxTeams: userData.maxTeams,
                      activePlans: userData.activePlans,
                      activeSubscriptions: userData.activeSubscriptions,
                      trialEligible:
                        userData.trialEligible === undefined ||
                        userData.trialEligible === true
                          ? true
                          : false,
                      showDashTutorial:
                        userData.showDashTutorial === undefined ||
                        userData.showDashTutorial === true
                          ? true
                          : false,
                      showTeamsTutorial:
                        userData.showTeamsTutorial === undefined ||
                        userData.showTeamsTutorial === true
                          ? true
                          : false,
                      numViewerTeams: userData.numViewerTeams,
                      coach: userData.coach,
                    };

                    this.token.next(userData.authToken); // update auth token observable

                    return retUser; // return the new user object
                  }),
                );
              }),
            );
          }
        }),
      )
      .subscribe((user) => {
        // subscribe to the mapped user object
        console.log('From AccountService Constructor: ', user);

        this.currentUser.next(user); // update the current user observable
      });
  }

  MpactButton = {
    text: 'TAKE THE MPACT',
    link: 'take-mpact',
    isroute: true,
  };
  // LoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  token: BehaviorSubject<string> = new BehaviorSubject(''); // observable of the user's auth token
  currentUser: BehaviorSubject<FBUser> = new BehaviorSubject(null); // observable of the current user

  ngUnsubscribe: Subject<any> = new Subject<any>();
  redirectUrl: string; // This is for the Auth Router Guard - if this exists, the user is sent here instead of evaluating their workflow stage
  loggingIn: BehaviorSubject<boolean> = new BehaviorSubject(false); // For triggering the login screens loading state when handling an alredy logged in user
  logginMessage: BehaviorSubject<string> = new BehaviorSubject('Logging in'); // For displaying login messages

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUser(): Observable<FBUser> {
    return user(this.auth).pipe(
      switchMap((user) => {
        if (!user) {
          // throw { message: 'User not logged in.', value: user };
          return EMPTY;
        } else {
          return from(user.getIdToken(true)).pipe(
            switchMap((token) => {
              return this.getUserInfo(user.uid).pipe(
                map((profile) => {
                  const userData = profile;
                  const retUser: FBUser = {
                    uid: user.uid,
                    authToken: userData.authToken,
                    displayName: user.displayName,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    roles: userData.roles,
                    workflowStage: userData.workflowStage,
                    couplet: userData.couplet,
                    prod: userData.prod ? true : false,
                    teams: userData.teams ? true : false,
                    token: token,
                  };

                  this.token.next(userData.authToken);
                  this.currentUser.next(retUser);

                  return retUser;
                }),
              );
            }),
          );
        }
      }),
    );
  }

  //
  // Watch for changes to the firebase auth state and handle the logic for a logged in user at different workflow stages
  //
  // async handleLoggedInUser(user: FBUser, redirectUrl?: string) {
  //   console.log('Got this FBUser from currentUser: ', user);
  //   if (!user) return; // Early exit if user is null
  //
  //   this.setLoggingIn(true, 'Logging in');
  //
  //   if (redirectUrl || this.redirectUrl) {
  //     this.handleRedirect(redirectUrl);
  //   } else {
  //     await this.handleWorkflowStages(user);
  //   }
  // }
  //
  // private async handleWorkflowStages(user: FBUser) {
  //   switch (user.workflowStage) {
  //     case 1:
  //       this.setLoggingIn(true, 'Redirecting to payment');
  //       this.router.navigate(['/payment']);
  //       break;
  //     case 2:
  //       await this.handleSurvey(user);
  //       break;
  //     case 3:
  //       this.updateUserAndNavigate(
  //         user,
  //         'Redirecting to dashboard',
  //         '/results/',
  //       );
  //       break;
  //     default:
  //       this.setLoggingIn(false, 'Logging in');
  //       this.toastService.showDanger(
  //         'There was an error automatically redirecting you to where you need to go. Please contact us for help.',
  //       );
  //       break;
  //   }
  // }
  //
  // private async handleSurvey(user: FBUser) {
  //   const surveyStatus = await this.fns
  //     .httpsCallable('getSurveyStatus')({ uid: user.uid })
  //     .toPromise();
  //   const nextStep = this.determineNextStepBasedOnSurvey(surveyStatus);
  //   this.updateUserAndNavigate(user, `Redirecting to ${nextStep}`, `/survey/`);
  // }
  //
  // private determineNextStepBasedOnSurvey(surveyStatus: any): string {
  //   switch (surveyStatus.assessment) {
  //     case 'in-process':
  //     case 'new':
  //       return 'assessment';
  //     case 'processed':
  //     case 'complete':
  //       return 'user information';
  //     default:
  //       return 'logging in';
  //   }
  // }
  //
  // private handleRedirect(redirectUrl?: string) {
  //   const url = redirectUrl || this.redirectUrl;
  //   console.log(
  //     'There is a redirect URL: ',
  //     this.redirectUrl,
  //     ' => ',
  //     redirectUrl,
  //   );
  //   this.setLoggingIn(true, 'Redirecting');
  //   this.router.navigate([url]);
  //   this.redirectUrl = null;
  // }
  //
  // private updateUserAndNavigate(
  //   user: FBUser,
  //   logMessage: string,
  //   basePath: string,
  // ) {
  //   const updateUserRecord = this.fns.httpsCallable('updateUserRecord');
  //   updateUserRecord(null)
  //     .toPromise()
  //     .then(
  //       () => {
  //         this.setLoggingIn(true, logMessage);
  //         this.token.next(user.authToken);
  //         this.router.navigate([`${basePath}${user.authToken}`]);
  //       },
  //       (err) => {
  //         console.error('Error updating user.', err);
  //         this.setLoggingIn(true, logMessage);
  //         this.token.next(user.authToken);
  //         this.router.navigate([`${basePath}${user.authToken}`]);
  //       },
  //     );
  // }
  //
  // private setLoggingIn(isLoggingIn: boolean, message: string) {
  //   this.loggingIn.next(isLoggingIn);
  //   this.logginMessage.next(message);
  // }

  async handleLoggedInUser(user: FBUser, redirectUrl?: string) {
    console.log('Got this FBUser from currentUser: ', user);

    if (!user) return; // if the current user is null drop everything and get out of here

    this.loggingIn.next(true);
    this.logginMessage.next('Logging in');

    if (this.redirectUrl || redirectUrl) {
      this.handleRedirect(redirectUrl);
    } else if (user.workflowStage == 1) {
      // If the user is registered but hasn't finished checkout send them to payment
      console.log('Doing stuff for workflow: ', user.workflowStage);
      this.logginMessage.next(`Redirecting to payment`);

      this.router.navigate(['/payment']);
      this.loggingIn.next(false);
    } else if (user.workflowStage == 2) {
      // If they haven't finished the survey, send them to their survey link
      console.log('Doing stuff for workflow: ', user.workflowStage);
      this.logginMessage.next(`Checking assessment status`);

      const surveyStatus = await this.fns
        .httpsCallable('getSurveyStatus')({ uid: user.uid })
        .toPromise();

      if (
        surveyStatus.assessment === 'in-process' ||
        surveyStatus.assessment === 'new'
      ) {
        console.log('Redirecting to survey: ', user.authToken);
        this.logginMessage.next(`Redirecting to assessment`);
        this.router.navigate(['/survey/' + user.authToken]);
        this.loggingIn.next(false);
      } else if (
        surveyStatus.assessment === 'processed' ||
        surveyStatus.assessment === 'complete'
      ) {
        this.handleUpdateUserRecord(user);
      } else {
        // Handle missing assessment status
        this.loggingIn.next(false);
        this.logginMessage.next(`Error`);
        this.toastService.showDanger(
          'There was an error automatically redirecting you to where you need to go. Please contact us for help.',
        );
      }
    } else if (user.workflowStage == 3) {
      // If they have finished the survey, send them to their dashboard
      console.log('Doing stuff for workflow: ', user.workflowStage);

      if (!user.couplet) {
        console.log('User record needs updated.');

        this.handleUpdateUserRecord(user);
      } else {
        this.logginMessage.next(`Redirecting to dashboard`);
        this.token.next(user.authToken);
        this.router.navigate(['/results/' + user.authToken]);
        this.loggingIn.next(false);
      }
    } else {
      // Handle missing workflow stage
      this.loggingIn.next(false);
      this.logginMessage.next(`Logging in`);
      this.toastService.showDanger(
        'There was an error automatically redirecting you to where you need to go. Please contact us for help.',
      );
    }
  }

  private handleRedirect(redirectUrl?: string) {
    // If there is a redirect url the user was sent to the login page by a route guard
    console.log(
      'There is a recirect URL: ',
      this.redirectUrl,
      ' => ',
      redirectUrl,
    );
    this.logginMessage.next(`Redirecting`);

    if (redirectUrl) {
      this.router.navigate([redirectUrl]); // Send them to the redirect url instead of redirecting based on workflowStage
      this.redirectUrl = null; // Reset the redirect url to prevent incorrect redirects
      this.loggingIn.next(false); // Set the logging in state to false
    } else {
      this.router.navigate([this.redirectUrl]); // Send them to the redirect url instead of redirecting based on workflowStage
      this.redirectUrl = null; // Reset the redirect url to prevent incorrect redirects
      this.loggingIn.next(false); // Set the logging in state to false
    }
  }

  private handleUpdateUserRecord(user: FBUser) {
    const updateUserRecord = this.fns.httpsCallable('updateUserRecord');

    this.logginMessage.next(`Updating user information`);
    updateUserRecord(null)
      .toPromise()
      .then(
        () => {
          this.token.next(user.authToken);
          this.router.navigate(['/results/' + user.authToken]);
          this.loggingIn.next(false);
        },
        () => {
          console.log('Error updating user.');
          this.token.next(user.authToken);
          this.router.navigate(['/results/' + user.authToken]);
          this.loggingIn.next(false);
        },
      );
  }

  //
  // logout the user
  //
  logout(loginPage: 'dash' | 'site') {
    signOut(this.auth).then(
      () => {
        this.currentUser.next(null); // update current user - this will also be updated to null by the subscription to the authstate but that takes too long

        console.log('User logged out.');
        this.toastService.showSuccess('User logged out successfullly!');
        // if running in standalone mode in iOS, redirect to results login route (these use the same login component)
        switch (loginPage) {
          case 'dash':
            this.router.navigate(['/dash/login']);
            break;
          case 'site':
            this.router.navigate(['/login']);
            break;
          default:
            this.router.navigate(['/login']);
            break;
        }
      },
      (error) => {
        console.error('Error logging out: ', error);
        this.toastService.showDanger('There was an error logging you out...');
      },
    );
  }

  //
  // Get the current user's user id.
  //
  getUserId() {
    return authState(this.auth).pipe(
      take(1),
      map((user) => {
        if (user) {
          return user.uid;
        } else {
          // throw { message: 'User not logged in.', value: user };
          return '';
        }
      }),
    );
  }

  //
  // Get the current user's information by their user id.
  //
  getUserInfo(userId: string) {
    return docData(doc(this.firestore, `users/${userId}`));
    // return this.db.collection<FBUser>('users').doc(userId).valueChanges()
  }

  //
  // Determine next action for user
  //
  nextStep(user: FBUser) {
    //Status: 1 = Checkout, 2 = Survey In Progress, 3 = Survey Complete
    console.log('From Next Steps: ', user);

    if (!user) {
      // if the current user is null drop everything and get out of here
      if (this.router.url === '/take-mpact') {
        this.MpactButton.text = 'CREATE AN ACCOUNT';
        this.MpactButton.link = 'register';
        this.MpactButton.isroute = true;
      } else {
        this.MpactButton.text = 'TAKE THE MPACT';
        this.MpactButton.link = '/take-mpact';
        this.MpactButton.isroute = true;
      }
    } else if (user.workflowStage == 1) {
      // If the user is registered but hasn't finished checkout send them to payment
      this.MpactButton.text = 'CREATE AN ACCOUNT';
      this.MpactButton.link = 'payment';
      this.MpactButton.isroute = true;

      // console.log("MPACT Buttons set to: ", this.MpactButton);
    } else if (user.workflowStage == 2) {
      // If they haven't finished the survey, send them to their survey link
      this.MpactButton.text = 'CONTINUE MPACT';
      this.MpactButton.link = `/survey/${user.authToken}`;
      this.MpactButton.isroute = false;

      // this.getContinueSurveyLink(user.authToken)
      //   .pipe(take(1))
      //   .subscribe((res) => {
      //     this.MpactButton.link = `/survey/${user.authToken}`;
      //   });
      // console.log("MPACT Buttons set to: ", this.MpactButton);
    } else if (user.workflowStage == 3) {
      // If they have finished the survey, send them to their dashboard
      this.token.next(user.authToken);
      this.MpactButton.text = 'MY DASHBOARD';
      this.MpactButton.link = `/dash/results/${user.authToken}`;
      this.MpactButton.isroute = true;

      // console.log("MPACT Buttons set to: ", this.MpactButton);
    } else {
      // Handle missing workflow stage

      if (this.router.url === '/take-mpact') {
        this.MpactButton.text = 'CREATE AN ACCOUNT';
        this.MpactButton.link = 'register';
        this.MpactButton.isroute = true;
      } else {
        this.MpactButton.text = 'TAKE THE MPACT';
        this.MpactButton.link = '/take-mpact';
        this.MpactButton.isroute = true;
      }

      // console.log("MPACT Buttons set to: ", this.MpactButton);
    }
  }

  // getContinueSurveyLink(authToken: string) {
  //   return this.http.get(
  //     'https://surveyservice.thinkaspirant.com/api/survey/getcontinuationquerystring',
  //     {
  //       withCredentials: true,
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         Authorization: `Survey-Token ${authToken}`,
  //       },
  //     },
  //   );
  // }

  //
  // Initiate the password reset process for this user
  //
  resetPasswordInit(email: string) {
    return sendPasswordResetEmail(this.auth, email, {
      url: 'http://localhost:4200/login',
    });
  }

  //
  // Lists users payment methods
  //
  listPaymentMethods(userId?: string) {
    const listPMs = this.fns.httpsCallable('lstPaymentMethods');

    if (userId) {
      return listPMs({
        userId: userId,
      });
    } else {
      return listPMs(null);
    }
  }

  //
  // Deletes a payment method
  //
  deletePaymentMethod(pmtMethod: string) {
    const deletePmtMthd = this.fns.httpsCallable('deletePaymentMethod');

    return deletePmtMthd({
      pmtMethod: pmtMethod,
    });
  }

  //
  // Sets a payment method as the customer's default
  //
  updateDefaultPaymentMethod(userId: string, pmtMethod: string) {
    const setDefaultPmtMthd = this.fns.httpsCallable('setDefaultPaymentMethod');

    return setDefaultPmtMthd({
      userId: userId,
      pmtMethod: pmtMethod,
    });
  }

  //
  // Lists users subscriptions
  //
  listSubscriptions(userId?: string) {
    const listSubs = this.fns.httpsCallable('listSubscriptions');

    if (userId) {
      return listSubs({
        userId: userId,
      });
    } else {
      return listSubs(null);
    }
  }

  //
  // Lists users subscriptions
  //
  listInvoices(userId?: string) {
    const listInvs = this.fns.httpsCallable('listInvoices');

    if (userId) {
      return listInvs({
        userId: userId,
      });
    } else {
      return listInvs(null);
    }
  }

  //
  // Create a subscription
  //
  createSubscription(
    userId: string,
    price: string,
    paymentMethod: string,
    coupon?: string,
    trialPeriod?: number,
  ) {
    const createSub = this.fns.httpsCallable('createSubscription');

    return createSub({
      userId: userId,
      plan: price,
      payment_method: paymentMethod,
      coupon: coupon,
      trialPeriod: trialPeriod,
    });
  }

  //
  // Cancels a subscription
  //
  cancelSubscription(userId: string, subscriptionId: string) {
    const cancelSub = this.fns.httpsCallable('cancelSubscription');

    return cancelSub({
      userId: userId,
      subscriptionId: subscriptionId,
    });
  }

  //
  // Update a subscription
  //
  updateSubscription(
    subscriptionId: string,
    newPlanId: string,
    updateType: 'upgrade' | 'downgrade' | 'scheduled',
    coupon?: string,
  ) {
    const updateSub = this.fns.httpsCallable('updateSubscription');

    return updateSub({
      subscriptionId: subscriptionId,
      newPlanId: newPlanId,
      updateType: updateType,
      coupon: coupon,
    });
  }

  //
  // Preview changes to a subscription
  //
  previewSubUpdates(
    subscriptionId: string,
    newPlanId: string,
    shouldProrate: boolean,
    coupon?: string,
  ) {
    const previewSub = this.fns.httpsCallable('previewSubUpdate');

    return previewSub({
      subscriptionId: subscriptionId,
      newPlanId: newPlanId,
      prorate: shouldProrate,
      coupon: coupon,
    });
  }

  //
  // Retrive upcoming invoice
  //
  upcomingInvoice(subscriptionId: string) {
    const upcoming = this.fns.httpsCallable('upcomingInvoice');

    return upcoming({
      subscriptionId: subscriptionId,
    });
  }

  //
  // Mark a specific tutorial video as seen
  //
  markTutorialSeen(tutType: 'dash' | 'teams') {
    const markTut = this.fns.httpsCallable('markTutorialSeen');

    return markTut({
      tutType: tutType,
    });
  }
}
