import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowScrollService {

  constructor() { }

  enableScroll() {
    document.body.classList.remove('noScroll');
  }

  disableScroll() {
    document.body.classList.add('noScroll');
  }

  toggleScroll() {
    document.body.classList.toggle('noScroll');
  }
  
}
