import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LoginComponent } from './shared/account/login/login.component';
import { RegisterComponent } from './shared/account/register/register.component';
import { PaymentComponent } from './shared/account/payment/payment.component';
import { ResetPasswordComponent } from './shared/account/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './shared/account/forgot-password/forgot-password.component';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { InViewDirective } from './directives/in-view.directive';
import { NgxProgressiveImageLoaderModule, IImageLoaderOptions } from 'ngx-progressive-image-loader';
import { VideoModalContainerComponent } from './shared/video-modal-container/video-modal-container.component';
import { GoogleSigninDirective } from './directives/google-signin.directive';
import { RequestComponent } from './shared/request/request.component';
import { TermsConditionsComponent } from './shared/terms-conditions/terms-conditions.component';

@NgModule({
  declarations: [
    InViewDirective,
    LoginComponent,
    RegisterComponent,
    PaymentComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    VideoModalContainerComponent,
    GoogleSigninDirective,
    RequestComponent,
    TermsConditionsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ScrollToModule.forRoot(),
    NgxProgressiveImageLoaderModule.forRoot(<IImageLoaderOptions>{
      // rootMargin must be specified in pixels or percent
      rootMargin: '30px',
      threshold: 0.1,
      // css filter
      // filter: 'blur(3px)',
      // image width / height ratio for image holder
      imageRatio: 16 / 9,
      // loading image in placeholder. Can be URL or base64
      placeholderImageSrc:
        // tslint:disable-next-line:max-line-length
        //'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICA8cGF0aCBmaWxsPSIjZGQwMDMxIiBkPSJNMTI1IDMwTDMxLjkgNjMuMmwxNC4yIDEyMy4xTDEyNSAyMzBsNzguOS00My43IDE0LjItMTIzLjF6Ii8+CiAgPHBhdGggZmlsbD0iI2MzMDAyZiIgZD0iTTEyNSAzMHYyMi4yLS4xVjIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMUwxMjUgMzB6Ii8+CiAgPHBhdGggZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo='
        '/assets/img/shared/loader2.svg'
    }),
  ],
  exports: [
    NgbModule,
    FormsModule,
    ScrollToModule,
    NgxProgressiveImageLoaderModule,
    InViewDirective,
    LoginComponent,
    RegisterComponent,
    PaymentComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    VideoModalContainerComponent,
  ]
})
export class CoreModule { }
