import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  showStandard(message: string) {
    this.show(message, { classname: 'info text-dark', delay: 5000 });
  }

  showSuccess(message: string) {
    this.show(message, { classname: 'success text-dark', delay: 5000 });
  }

  showDanger(message: string) {
    this.show(message, { classname: 'danger text-dark', delay: 5000 });
  }
}
