import { Component, HostListener, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { VideoModalService } from 'src/app/services/video-modal.service';

@Component({
  selector: 'app-video-modal-container',
  templateUrl: './video-modal-container.component.html',
  styleUrls: ['./video-modal-container.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms ease', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class VideoModalContainerComponent implements OnInit {
  native = false;

  constructor(
    private VideoModalService: VideoModalService,
  ) { }

  ngOnInit(): void {
  }

  toggleVideoOverlay(src?: string, native?: boolean) {
    if(native != undefined) 
      this.native = native;

    this.VideoModalService.toggleVideoOverlay(src);
  }

  @HostListener('document:keydown.escape', ['$event']) onEscKeyHandler(event: KeyboardEvent) {
    // console.log(event);
    this.VideoModalService.showVideoOverlay = false;
  }

  get videoLink(): BehaviorSubject<SafeResourceUrl> {
    return this.VideoModalService.videoLink;
  }
  get showVideoOverlay(): boolean {
    return this.VideoModalService.showVideoOverlay;
  }
  closeModal() {
    this.VideoModalService.closeModal();
  }
}
