import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  success = false;
  fail = false;
  loadingMessage = "Accepting request..."

  constructor(
    private route: ActivatedRoute,
    private fns: AngularFireFunctions,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.route.queryParamMap.subscribe(qParams => {
        console.log(params.get('reqId'));
        console.log(qParams.get('accept'));

        if(qParams.get('accept')) {
          const acceptRequest = this.fns.httpsCallable('acceptRequest');
  
          acceptRequest({
            reqId: params.get('reqId'),
          }).pipe(take(1)).subscribe(res => {
            console.log(res);
            
            if(res.success) {
              this.success = true;
            } 
            else {
              this.fail = true;
            }
          })
        }
      })
    })
  }

}
