<div
  class="news-container position-fixed bottom-0"
  [ngClass]="{ open: open }"
  *ngIf="!isDash() && !isLanding() && !isEmbed() && !isSurvey()"
>
  <button
    class="btn btn-link position-absolute top-0 end-0"
    (click)="open = false"
  >
    <i class="fal fa-times"></i>
  </button>
  <div
    class="row pt-2 pb-2 ps-1 pe-3 banner-link justify-content-center"
    [routerLink]="'/landing/ebook-download'"
    (click)="open = false"
  >
    <div class="col-12">
      <div class="row">
        <div class="col-3 d-flex align-items-center justify-content-center">
          <img
            class="img-fluid ms-3"
            src="/assets/img/landing/ebook/eBook-cover.png"
            alt="News Banner Image"
          />
        </div>
        <div
          class="col-9 text-center d-flex flex-column align-items-center justify-content-center"
        >
          <strong>Learn How to Elevate Your Team!</strong>
          <p>Identify & activate the 5 keys to a flourishing team</p>
          <button class="btn btn-light takeMpactBtn text-dark py-2 px-4">
            DOWNLOAD EBOOK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
