import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class VideoModalService {
  showVideoOverlay = false;
  videoLink: BehaviorSubject<SafeResourceUrl> = new BehaviorSubject('');
  tutorialType: BehaviorSubject<'dash' | 'teams'> = new BehaviorSubject(null);

  constructor(
    private sanitize: DomSanitizer,
    private AccountService: AccountService,
  ) { }

  toggleVideoOverlay(src?: string, tutType?: 'dash' | 'teams') {
    let source = this.sanitize.bypassSecurityTrustResourceUrl(src) || '';
    this.videoLink.next(source);
    this.showVideoOverlay = !this.showVideoOverlay;

    if(tutType) {
      this.tutorialType.next(tutType);
    }
  }

  closeModal() {
    if(this.tutorialType.value) {
      this.AccountService.markTutorialSeen(this.tutorialType.value).toPromise().catch(error => {
        console.log("Error updating tutorial status: ", error);
      });
      
      this.tutorialType.next(null);
    }
  }
}
