import { Component, OnInit, OnDestroy, ApplicationRef } from '@angular/core';
import { Auth, authState, user } from '@angular/fire/auth';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ConnectionService } from 'ng-connection-service';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LayoutService } from './services/layout.service';
import { ToastService } from './services/toast.service';
import { WindowScrollService } from './services/window-scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'MPACT Assessment';
  hideHeader = false;
  hideFooter = false;
  topNavNoBg = false;
  routerEvents: Subscription;
  previousUrl: string = null;
  currentUrl: string = null;

  constructor(
    public layout: LayoutService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private scroll: WindowScrollService,
    private connectionService: ConnectionService,
    private update: SwUpdate,
    private appRef: ApplicationRef,
    private auth: Auth,
  ) {
    this.updateClient();
    this.checkUpdate();

    user(this.auth).subscribe((user) => {
      console.log('User: ', user);
    });

    authState(this.auth).subscribe((user) => {
      console.log('Auth State: ', user);
    });
  }

  updateClient() {
    if (!this.update.isEnabled) {
      console.log('Not Enabled');
      return;
    }

    this.update.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        console.log(`Current version: ${event.currentVersion.hash}`);
        console.log(`New version ready: ${event.latestVersion.hash}`);
        // if (confirm("A new version of this App is available! Click 'Okay' to refresh and update.")) {
        this.update.activateUpdate().then(() => location.reload());
        // }
      } else if (event.type === 'VERSION_DETECTED') {
        console.log(`New version detected: ${event.version.hash}`);
        // Additional logic for handling detected version
      } else if (event.type === 'VERSION_INSTALLATION_FAILED') {
        console.log(
          `Failed to install version '${event.version.hash}': ${event.error}`,
        );
        // Error handling logic
      }
    });
  }

  // updateClient() {
  //   if (!this.update.isEnabled) {
  //     console.log('Not Enabled');
  //     return;
  //   }
  //
  //   this.update.available.subscribe((event) => {
  //     console.log(`current`, event.current, `available `, event.available);
  //     // if (confirm("A new version of this App is available! Click 'Okay' to refresh and update.")) {
  //     this.update.activateUpdate().then(() => location.reload());
  //     // }
  //   });
  //
  //   this.update.activated.subscribe((event) => {
  //     console.log(`current`, event.previous, `available `, event.current);
  //   });
  // }

  checkUpdate() {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(8 * 60 * 60 * 1000);

        timeInterval.subscribe(() => {
          this.update.checkForUpdate().then(() => console.log('checked'));
          console.log('update checked');
        });
      }
    });
  }

  ngOnInit() {
    this.routerEvents = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          this.hideHeader =
            this.route.firstChild.snapshot.data.hideHeader == true
              ? true
              : false;
          this.hideFooter =
            this.route.firstChild.snapshot.data.hideFooter == true
              ? true
              : false;
          this.topNavNoBg =
            this.route.firstChild.snapshot.data.topNavNoBg == true
              ? true
              : false;

          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;

          this.scroll.enableScroll();

          if (!this.previousUrl || !this.currentUrl) {
            return;
          }
          if (
            this.currentUrl.includes('/dash') &&
            (!this.previousUrl.includes('/dash') ||
              this.previousUrl.includes('/login'))
          ) {
            window.scrollTo(0, 0);
          } else if (this.hideFooter && this.hideHeader) {
            return;
          } else {
            window.scrollTo(0, 0);
          }
        }
      });

    this.connectionService.monitor().subscribe((isConnected) => {
      // isConnected;
      if (isConnected) {
        // this.status = "ONLINE";
        this.toastService.showSuccess('You are back online.');
      } else {
        // this.status = "OFFLINE";
        this.toastService.showDanger(
          'You are now offline. Some parts of the app may not work properly until you are back oneline.',
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.routerEvents) this.routerEvents.unsubscribe();
  }

  get showLoadingOverlay(): BehaviorSubject<boolean> {
    return this.layout.showLoadingOverlay;
  }

  get loadingOverlayMessage(): BehaviorSubject<string> {
    return this.layout.loadingOverlayMessage;
  }
}
