export const environment = {
  production: true,
  firebase: {
    projectId: 'mpact-test',
    appId: '1:1053099213090:web:e275ff9594c2b5bfaae57b',
    storageBucket: 'mpact-test.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCh11AiLv9U7uQriQj4xumewvpnW2hAxK4',
    authDomain: 'mpact-test.firebaseapp.com',
    messagingSenderId: '1053099213090',
    measurementId: 'G-1FW97JV2P9',
  }
};
