<div id="ResetPWPage">
    <div id="ResetPWContainer">
        <div id="ResetPwFormContainer" class="container">
            <div class="row">
                <div class="col-md-12">
                    <div id="ResetPWForm" *ngIf="success && !fail">
                        <div class="row">
                            <div class="d-flex justify-content-center align-items-center flex-column" style="width: 100%; height: 375px;">
                                <i class="far fa-check fa-3x mentoring-color"></i>
                                <p>
                                    Request accepted!
                                </p>
                                <small>
                                    You can close this window.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div id="ResetPWForm" *ngIf="fail && !success">
                        <div class="row">
                            <div class="d-flex justify-content-center align-items-center flex-column" style="width: 100%; height: 375px;">
                                <i class="far fa-times fa-3x pioneering-color"></i>
                                <p class="text-center">
                                    This request is no longer valid. For more info, reach out to the person who invited you.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <ng-template #acceptRequestLoader> -->
                        <div id="ResetPWForm" *ngIf="!fail && !success">
                            <div class="row">
                                <div class="d-flex justify-content-center align-items-center flex-column"
                                    style="width: 100%; height: 375px;">
                                    <div class="spinner-border spinner-border-md" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <p class="mt-1">
                                        {{ loadingMessage }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    <!-- </ng-template> -->
                </div>
            </div>
        </div>
    </div>
</div>