import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequestComponent } from './shared/request/request.component';

const routes: Routes = [
  {
    path: '',
    data: { topNavNoBg: true },
    loadChildren: () => import('./site/site.module').then((m) => m.SiteModule),
  },
  {
    path: 'dash',
    data: { hideHeader: true, hideFooter: true },
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'results/:authToken',
    redirectTo: 'dash/results/:authToken',
    pathMatch: 'prefix',
  },
  { path: 'request/:reqId', component: RequestComponent },
  {
    path: 'pdf',
    loadChildren: () => import('./pdf/pdf.module').then((m) => m.PdfModule),
  },
  {
    path: 'embed',
    loadChildren: () =>
      import('./embed/embed.module').then((m) => m.EmbedModule),
  },
  {
    path: 'survey',
    loadChildren: () =>
      import('./survey/survey.module').then((m) => m.SurveyModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
