<ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="true"
    [delay]="toast.delay || 5000" [header]="toast.header" (hidden)="toastService.remove(toast)" (click)="toastService.remove(toast)">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
        <div class="d-flex align-items-center">
            <div [innerHTML]="toast.textOrTpl"></div><i class="fal fa-times ms-auto ps-2"></i>
        </div>
    </ng-template>
</ngb-toast>